import 'core-js/features/dom-collections/for-each';
import 'core-js/features/promise';

export default (el) => {
    el.querySelectorAll('[data-component]').forEach((el) => {
        if (typeof el.dataset !== 'undefined') {
            import('../' + el.dataset.component + '.js').then((component) => {
                component.default(el);
            });
        }
    });
};
